.borderbox_profu img {
    width: 211px;
    height: 180px;
    object-fit: cover;
}
.profilepagebutton {
    text-align: center;
}
.homebrnprof {
    font-weight: 600 !important;
    font-size: 16px !important;
    background: linear-gradient(180deg, rgb(222 0 76 / 50%) 0%, rgb(139 75 97 / 41%) 45%, rgb(139 75 97 / 41%) 55%, rgb(10 10 10 / 32%) 100%);
    width: 217px;
    margin: 0 auto 16px auto !important;
    display: block;
    padding: 15px !important;
}

.chpswandstartgm {
    text-align: center;
    margin-top: 0 !important;
}

.chpswandstartgm a p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px #DE004C;
    margin-bottom: 30px;
}
.volumeicon {
    cursor: pointer;
}
.hidediv {
    display:none;
}