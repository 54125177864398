.borderbox_profu img {
    width: 211px;
    height: 180px;
    object-fit: cover;
}
.profilepagebutton {
    text-align: center;
}
.termsandpri {
    text-align: center;
}