input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.register-btn,#register-btn{
color: #FFF !important;
    text-align: center !important;
    font-family: Montserrat !important;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: 1.26px !important;
    }

 #password{background:linear-gradient(180deg, rgb(222 0 76 / 50%) 0%, rgb(139 75 97 / 41%) 45%, rgb(139 75 97 / 41%) 55%, rgb(10 10 10 / 32%) 100%) !important;box-shadow: unset;border: 1px solid #E0004D !important;}   
.erroor p {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 7px;
}

 