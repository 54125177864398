label.inpuupload {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 999999999999;
    width: 100%;
}

.picture-icon {
    position: relative;
}

.inpuupload input[type="file"] {
    opacity: 0;
}
/*.piclinkupload button {
    position: relative;
    width: auto;
    height: auto;
    padding: 0 !important;
    border: 0 !important;
}*/


.piclinkupload button span{
 color: #FFF !important;
    text-align: center !important;
    font-family: Montserrat !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal !important;
    letter-spacing: 1.26px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}