.contentpage h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.08em;
    color: #FFFFFF;
    margin-top: 18px;
}
.contentpage {
    padding: 40px;
}
.content_par {font-family: 'Montserrat';font-style: normal;font-weight: 600;font-size: 12px;line-height: 25px;letter-spacing: 0.08em;color: #FFFFFF;margin-top: 16px;}