.appendmianclas {
    display: inline-block;
    width: 100%;
}
.plyer-icon {
    position: relative;
}
.appendata {
    display: inline-block;
    width: 100%;
}
.plyer-icon img {
    height: 55px !important;
}