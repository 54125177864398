iframe {
    /* background: linear-gradient(180deg, rgb(222 0 76 / 50%) 0%, rgb(139 75 97 / 41%) 45%, rgb(139 75 97 / 41%) 55%, rgb(10 10 10 / 32%) 100%); */
    /* width: 217px; */
    margin: 0 auto 16px auto !important;
    /* display: block; */
    font-size: 12px !important;
    /* font-weight: 300 !important; */
    /* border-radius: 10px !important; */
    /* border: 1px solid #DE004C !important; */
    /* background: linear-gradient(180deg, rgba(222, 0, 76, 0.60) 0%, rgba(255, 255, 255, 0.00) 100%); */
    /* mix-blend-mode: screen; */
    /* width: 100%; */
    /* color: #FFF !important; */
    /* text-align: center !important; */
    /* font-family: Montserrat !important; */
    /* font-size: 18px !important; */
    /* font-style: normal; */
    /* font-weight: 600 !important; */
    /* line-height: normal !important; */
    /* letter-spacing: 1.26px !important; */
}

.emailcontent {
    color: green;
    display: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.08em;
    color: #FFFFFF;
    padding-left: 6px;
}